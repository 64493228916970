import { axiosAuth, axiosLocal } from "./axiosInstance";

export const getAllServices = async() =>{
    try {
      const response = await axiosAuth.get('/management/services/get-all-services');
      return response.data
    } catch (error) {
        return error.message || "An error occured while trying to get all services."
      // Handle the error appropriately
    }
};

export const getAllNotices = async() =>{
  try {
    const response = await axiosLocal.get('/management/notices/get-all-notices');
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to get all notices."
    // Handle the error appropriately
  }
};

export const getAllCategories = async() =>{
  try {
    const response = await axiosLocal.get('/blog/get_all_blog_categories');
    return response.data.data
  } catch (error) {
      return error.message || "An error occured while trying to get all notices."
  }
}

export const getAllTickets = async() =>{
  try {
    const response = await axiosAuth.get('/tickets/get-all-tickets');
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to get all tickets."
    // Handle the error appropriately
  }
};

export const getAllBlogs = async() =>{
  try {
    const response = await axiosLocal.get('/blog/all_blogs');
    return response.data.data
  } catch (error) {
      return error.message || "An error occured while trying to get all notices."
    // Handle the error appropriately
  }
};

export const getBlogById = async({params}) =>{
  let {_id}=params;
  try {
    const response = await axiosLocal.post("/blog/get_blog_by_id",{_id:_id});
    return response.data.data
  } catch (error) {
      return error.message || "An error occured while trying to get all notices."
    // Handle the error appropriately
  }
};

export const getBlogByIdAndAllCategories = async({params})=>{
  // const {_id} = params 
  const getBlogByid=getBlogBySlug({params});
  const allCategories=getAllServices();
  return Promise.all([getBlogByid,allCategories]).then((results)=>{
   return {
    allBlogs: results[0],
    allCategories: results[1],
   }
  })
}

export const getNoticeBySeoTitleAndAllServices = async({params})=>{
  // const {_id} = params 
  const getNoticeBySeoTitleData= await getNoticeBySeoTitle({params});
  const getAllServicesData= await getAllServices();
  return Promise.all([getNoticeBySeoTitleData,getAllServicesData]).then((results)=>{
   return {
    getNoticeBySeoTitle: results[0],
    getAllServices: results[1],
   }
  })
}

export const getTicketDetails = async({params}) =>{
  try {
    let {ticketNo} = params
    const response = await axiosAuth.get('/tickets/get-by-ticketNo/'+ticketNo);
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to get all tickets."
    // Handle the error appropriately
  }
};

export const getProfile = async() =>{
  try {
    const response = await axiosAuth.get('/user/getProfile');
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to get profile."
    // Handle the error appropriately
  }
};

export const getAllCases = async() =>{
  try {
    const response = await axiosAuth.get('/cases/get-all-cases');
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to get all cases."
    // Handle the error appropriately
  }
};

export const getCaseDetails = async({params}) =>{
  try {
    let {caseNo} = params
    const response = await axiosAuth.get('/cases/get-by-caseNo/'+caseNo);
    return response.data
  } catch (error) {
      return error.message || "An error occured while trying to case data."
    // Handle the error appropriately
  }
};

export const getHomepageData  = async () => {
  const urls = [`/management/notices/get-all-notices`, `/blog/all_blogs`];

  try {
    const responses = await Promise.all(urls.map(url => axiosLocal.get(url)));

    // Initialize an object with keys representing the source of the data
    let data = {
      allNotices: responses[0].data,   // assuming the first API is for artist requests
      allNewsFeed: responses[1].data.data
    };

    return data;
  } catch (error) {
    return error.message || "An error occurred while trying to get artist homepage initialdata.";
  }
};

export const getNoticeBySeoTitle = async({params}) =>{
    let {seo_url} = params

  const urls = [`/management/notices/get-all-notices`, `/management/notices/get-notice-by-seo-title/${seo_url}`];

  try {
    const responses = await Promise.all(urls.map(url => axiosAuth.get(url)));

    // Initialize an object with keys representing the source of the data
    let data = {
      allNotices: responses[0].data,   // assuming the first API is for artist requests
      notice: responses[1].data
    };

    return data;
  } catch (error) {
    return error.message || "An error occurred while trying to get artist homepage initialdata.";
  }
};

export const getBlogBySlug = async({params}) =>{
  const {slug} = params 
  try {
    const response = await axiosAuth.get(`/blog/get_blog_by_slug/${slug}`);
    return response.data.data
  } catch (error) {
      return error.message || "An error occured while trying to get profile."
  }
};

export const getServiceById = async({params}) =>{
  const {_id} = params 
  try {
    const response = await axiosAuth.get(`/management/services/get-service-by-id/${_id}`);
    return response.data.data
  } catch (error) {
      return error.message || "An error occured while trying to get profile."
  }
};

export const getCategoriesById = async({params}) => {
  let {_id} = params;
  try {
    const response = await axiosLocal.get(`/blog/get_category_by_id/${_id}`)
    return response.data.data
  } catch (error) {
    return error.message || "An error occured while trying to get all notices."
  }
}

export const getAllUsers = async() => {
  try {
    const response = await axiosAuth.get('/user/get-all-users')
    return response.data.data
  } catch (error) {
    return error.message || "An error occured while trying to get all users."
  }
}

export const getAllPayments = async() => {
  try {
    const resposne = await axiosAuth.get('/payment/get-all-payments')
    return resposne.data.data
  } catch (error) {
    return error.message || "An error occured while trying to get all payments."
  }
}

export const getDashboardData  = async () => {
  const urls = [`/user/getProfile`, `/tickets/get-all-tickets-dashboard`, `/cases/get-all-cases`, `/user/get-recent-users`, `/payment/get-all-payments`, `/tickets/get-all-tickets`];

  try {
    const responses = await Promise.all(urls.map(url => axiosAuth.get(url)));
    // Initialize an object with keys representing the source of the data
    if(responses){
      let data = {
        profile : responses[0].data,   // assuming the first API is for artist requests
        tickets : responses[1].data,
        cases : responses[2].data,
        users : responses[3].data.data,
        totalPayment : responses[4].data.data,        
        casesGraph : responses[5].data
      };
      return data;
    } 

    
  } catch (error) {
    return error.message || "An error occurred while trying to get dashboard data.";
  }
};

export const getSingleBlogPageData = async ({params}) => {
  const {slug} = params 
  const urls = [`/blog/get_blog_by_slug/${slug}`, `/blog/all_blogs`];

  try {
    const responses = await Promise.all(urls.map(url => axiosAuth.get(url)));
    // Initialize an object with keys representing the source of the data
    if(responses){
      let data = {
        blog : responses[0].data?.data,   // assuming the first API is for artist requests
        allBlogs : responses[1].data?.data
      };
      return data;
    } 

    
  } catch (error) {
    return error.message || "An error occurred while trying to get dashboard data.";
  }
};

export const getAllQuerys = async() => {
  try {
    const resposne = await axiosAuth.get('/queries/all_queries')
    return resposne.data.data
  } catch (error) {
    return error.message || "An error occured while trying to get all query."
  }
}

export const getSiteMapData = async () => {
  const urls = [`/management/notices/get-all-notices`, `/blog/all_blogs`];

  try {
    const responses = await Promise.all(urls.map(url => axiosAuth.get(url)));
    // Initialize an object with keys representing the source of the data
    if(responses){
      let data = {
        notices : responses[0].data,   // assuming the first API is for artist requests
        blogs : responses[1].data?.data
      };
      return data;
    } 

    
  } catch (error) {
    return error.message || "An error occurred while trying to get dashboard data.";
  }
};
