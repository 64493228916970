import React, { useEffect, Suspense } from 'react';
import { Navigate, Outlet, Route, createBrowserRouter, createRoutesFromElements, useLocation, useNavigation } from 'react-router-dom';
import { getAllServices, getAllNotices, getAllCategories, getAllBlogs, getAllTickets, getTicketDetails, getProfile, getAllCases,getHomepageData,getBlogByIdAndAllCategories, getNoticeBySeoTitle, getNoticeBySeoTitleAndAllServices, getServiceById, getCategoriesById, getAllUsers, getAllPayments, getDashboardData, getAllQuerys } from 'configs/initialApis';
import { checkAuth } from 'configs/auth';
import { getCaseDetails, getSingleBlogPageData } from 'configs/initialApis';
import { getSiteMapData } from 'configs/initialApis';

const Homepage = React.lazy(() => import('pages/home'));
const LoginPage = React.lazy(() => import('pages/Login'));
const DefaultLayout = React.lazy(() => import('layouts/layouts.default'));
const DashboardComponents = React.lazy(() => import('layouts/layouts.management'));
const UserLayout = React.lazy(() => import('layouts/theme/UserLayout'));
const AboutUs = React.lazy(() => import('pages/about'));
const ProfilePage = React.lazy(() => import('pages/user/profile'));
const EditProfile = React.lazy(() => import('pages/user/profile/editProfile'));
const Dashboard = React.lazy(() => import('pages/managements/dashboard'));
const AllServices = React.lazy(() => import('pages/managements/services/allServices'));
const CreateNewService = React.lazy(() => import('pages/managements/services/createService'));
const AllNotices = React.lazy(() => import('pages/managements/notices/allNotices'));
const CreateNewNotice = React.lazy(() => import('pages/managements/notices/createNotice'));
const UploadNotice = React.lazy(() => import('pages/UploadNotice/uploadNotice'));
const NoDataFound = React.lazy(() => import('pages/error/404'));
const UserTickets = React.lazy(() => import('pages/user/tickets'));
const AllBlogs = React.lazy(() => import('pages/managements/blogs/allBlogs'));
const CreateBlogs = React.lazy(() => import('pages/managements/blogs/createBlogs'));
const AllCategories = React.lazy(() => import('pages/managements/categories/allCategories'));
const CategoryForm = React.lazy(() => import('pages/managements/categories/createCategory'));
const Alltickets = React.lazy(() => import('pages/managements/tickets/alltickets'));
const PrivacyPolicy = React.lazy(() => import('pages/privacyPolicy'));
const TermsConditions = React.lazy(() => import('pages/termsConditions'));
const ContactUs = React.lazy(() => import('pages/contactus'));
const CancellationandRefund = React.lazy(() => import('pages/cancellation and refund'));
const ShippingandDelivery = React.lazy(() => import('pages/shipping & delivery'));
const Signup = React.lazy(() => import('pages/Login/signup'));
const UserCases = React.lazy(() => import('pages/user/cases'));
const AllBlogsList = React.lazy(() => import('pages/allBlogs/allBlogs'));
const SingleBlog = React.lazy(() => import('pages/singleBlog.js'));
const ServicesPage = React.lazy(() => import('pages/services'));
const SingleService = React.lazy(() => import('pages/singleService'));
const Updatefaqpage = React.lazy(() => import('pages/updateFaq'));
const AllCases = React.lazy(() => import('pages/managements/cases/allCases'));
const EditCase = React.lazy(() => import('pages/managements/cases/edit'));
const UpdateBlogs = React.lazy(() => import('pages/managements/blogs/updateBlogs'));
const UpdateNotice = React.lazy(() => import('pages/managements/notices/updateNotice'));
const UpdateService = React.lazy(() => import('pages/managements/services/updateService'));
const Disclaimer = React.lazy(() => import('pages/disclaimer'));
const Sitemap = React.lazy(() => import('pages/sitemap'));
const UpdateCategory = React.lazy(() => import('pages/managements/categories/updateCategory'));
const UserList = React.lazy(() => import('pages/managements/users/userList'));
const AllPayments = React.lazy(() => import('pages/managements/payments/allPayments'));
const EditNewTicket = React.lazy(() => import('pages/managements/tickets/viewTicket'));
const Query = React.lazy(() => import('pages/managements/query'));
const PageNotFound = React.lazy(() => import('pages/Errors/404'));
const GlobalLoader = React.lazy(() => import('views/common/loader'));

const MainWrapper = () => {

  const location = useLocation()
  const {state} = useNavigation();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname])

  if(state === 'loading'){
    return <GlobalLoader/>
  }

  return <Outlet />;
}

const roles = {
  user: process.env.REACT_APP_ROLE_USER,
  admin: process.env.REACT_APP_ROLE_ADMIN,
}

const RequireAuth = ({ allowedRoles }) => {

  const location = useLocation()
    const decoded = checkAuth();
    if (decoded && decoded.role && decoded.userID) {
      if (allowedRoles && Array.isArray(allowedRoles)) {
        let userAllowed = allowedRoles.includes(decoded.role);
        if (userAllowed) {
          return <Outlet />;
        }
        else {
          return <NoDataFound />;
        }
      }
      else {
        return <Navigate to={`/login?redirectUrl=${location.pathname}`} replace={true} />
      }
    }
    else {
      return <Navigate to={`/login?redirectUrl=${location.pathname}`} replace={true} />
    }

}

const CheckLoggedIn = () => {
  const check = checkAuth();

  if (check) {
    if (check.role == roles.user || check.role == roles.artist) {
      return <Navigate to="/" replace={true} />
    }
    else if (check.role == roles.admin) {
      return <Navigate to="/management/dashboard" replace={true} />
    }
    return <Navigate to="/" replace={true} />
  }
  else {
    return <Outlet />;
  }
}

const ApplicationRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Suspense fallback={<GlobalLoader />}><MainWrapper /></Suspense>} errorElement={<Suspense fallback={<GlobalLoader />}><PageNotFound /></Suspense>}>
      <Route element={<Suspense fallback={<GlobalLoader />}><DefaultLayout/></Suspense>} loader={getHomepageData}>
        <Route index path='/' element={<Suspense fallback={<GlobalLoader />}><Homepage/></Suspense>} loader={getHomepageData}/>
        <Route path="/about-us" element={<Suspense fallback={<GlobalLoader />}><AboutUs/></Suspense>}/>
        <Route path='/privacy-policy' element={<Suspense fallback={<GlobalLoader />}><PrivacyPolicy/></Suspense>} />
        <Route path='/services'  element={<Suspense fallback={<GlobalLoader />}><ServicesPage/></Suspense>} loader={getAllNotices}/>
        <Route path='/services/:seo_url' element={<Suspense fallback={<GlobalLoader />}><SingleService/></Suspense> } loader={getNoticeBySeoTitle} errorElement={<PageNotFound layoutOff={true}/>}/>
        <Route path='/frequently-asked-questions' element={<Navigate to={`/frequently-asked-questions/general-queries`} replace={true} />}/>
        <Route path='/frequently-asked-questions/:faq_slug' element={<Suspense fallback={<GlobalLoader />}><Updatefaqpage/></Suspense>}/>
        <Route path='/terms-condition' element={<Suspense fallback={<GlobalLoader />}><TermsConditions/></Suspense>}/>
        <Route path="/contact-us" element={<Suspense fallback={<GlobalLoader />}><ContactUs/></Suspense>}/>
        <Route path="/shipping-and-delivery" element={<Suspense fallback={<GlobalLoader />}><ShippingandDelivery/></Suspense>}/>
        <Route path="/cancellation-refund" element={<Suspense fallback={<GlobalLoader />}><CancellationandRefund/></Suspense>}/>
        <Route path="/disclaimer" element={<Suspense fallback={<GlobalLoader />}><Disclaimer/></Suspense>}/>
        <Route path="/sitemap" element={<Suspense fallback={<GlobalLoader />} ><Sitemap/></Suspense>} loader={getSiteMapData}/>
        <Route path="/blogs" element={<Suspense fallback={<GlobalLoader />}><AllBlogsList/></Suspense>} loader={getAllBlogs}/>
        <Route path="/blogs/:slug" element={<Suspense fallback={<GlobalLoader />}><SingleBlog/></Suspense>} loader={getSingleBlogPageData}/>
         {/* Auth routes for user */}
        <Route element={<RequireAuth allowedRoles={[roles.user]} />}>
          <Route path="/upload-notice" element={<Suspense fallback={<GlobalLoader />}><UploadNotice/></Suspense>}  loader={getAllNotices}/>
          <Route path="/user/tickets" element={<Suspense fallback={<GlobalLoader />}><UserTickets/></Suspense>} loader={getAllTickets}/>
          <Route path="/user/cases" element={<Suspense fallback={<GlobalLoader />}><UserCases/></Suspense>} loader={getAllCases}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={[roles.user,roles.admin]} />}>
          <Route path='/user/profile' element={<Suspense fallback={<GlobalLoader />}><ProfilePage/></Suspense>} loader={getProfile}/>
          <Route path='/user/profile/edit' element={<Suspense fallback={<GlobalLoader />}><EditProfile/></Suspense>} loader={getProfile}/>
        </Route>

      </Route>
      <Route element={<Suspense fallback={<GlobalLoader />}><CheckLoggedIn /></Suspense>}>
        <Route path="/login" element={<Suspense fallback={<GlobalLoader />}><LoginPage/></Suspense>} />
        <Route path='/signup' element={<Suspense fallback={<GlobalLoader />}><Signup /></Suspense>} />
      </Route>
      <Route element={<RequireAuth allowedRoles={[roles.admin]} />}>
      <Route element={<Suspense fallback={<GlobalLoader />}><DashboardComponents /></Suspense>}>
          <Route element={<Suspense fallback={<GlobalLoader />}><UserLayout /></Suspense>}>
            <Route path='/management/dashboard' element={<Suspense fallback={<GlobalLoader />}><Dashboard /></Suspense>} loader={getDashboardData} />
            <Route path='/management/services' element={<Suspense fallback={<GlobalLoader />}><AllServices /></Suspense>} loader={getAllServices}/>
            <Route path='/management/services/add-new' element={<Suspense fallback={<GlobalLoader />}><CreateNewService /></Suspense>} />
            <Route path='/management/services/:_id' element={<Suspense fallback={<GlobalLoader />}><UpdateService /></Suspense>} loader={getServiceById}/>
            <Route path='/management/notices' element={<Suspense fallback={<GlobalLoader />}><AllNotices /></Suspense>} loader={getAllNotices}/>
            <Route path='/management/notices/add-new' element={<Suspense fallback={<GlobalLoader />}><CreateNewNotice /></Suspense>}  loader={getAllServices}/>
            <Route path='/management/notices/:seo_url' element={<Suspense fallback={<GlobalLoader />}><UpdateNotice /></Suspense>} loader={getNoticeBySeoTitleAndAllServices}/>
            <Route path='/management/blogs' element={<Suspense fallback={<GlobalLoader />}><AllBlogs /></Suspense>} loader={getAllBlogs}/>
            <Route path='/management/blogs/:slug' element={<Suspense fallback={<GlobalLoader />}><UpdateBlogs /></Suspense>} loader={getBlogByIdAndAllCategories}/>
            <Route path='/management/blogs/add-new' element={<Suspense fallback={<GlobalLoader />}><CreateBlogs /></Suspense>} loader={getAllCategories}/>
            <Route path='/management/categories' element={<Suspense fallback={<GlobalLoader />}><AllCategories /></Suspense>} loader={getAllCategories}/>
            <Route path='/management/categories/:_id' element={<Suspense fallback={<GlobalLoader />}><UpdateCategory /></Suspense>} loader={getCategoriesById} />
            <Route path='/management/categories/add-new' element={<Suspense fallback={<GlobalLoader />}><CategoryForm /></Suspense>}/>
            <Route path='/management/tickets' element={<Suspense fallback={<GlobalLoader />}><Alltickets /></Suspense>} loader={getAllTickets}/>
            <Route path='/management/tickets/:ticketNo' element={<Suspense fallback={<GlobalLoader />}><EditNewTicket /></Suspense>} loader={getTicketDetails}/>
            <Route path='/management/cases' element={<Suspense fallback={<GlobalLoader />}><AllCases /></Suspense>} loader={getAllCases}/>
            <Route path='/management/cases/:caseNo' element={<Suspense fallback={<GlobalLoader />}><EditCase /></Suspense>} loader={getCaseDetails}/>
            <Route path='/management/users' element={<Suspense fallback={<GlobalLoader />}><UserList /></Suspense>} loader={getAllUsers} />
            <Route path='/management/payments' element={<Suspense fallback={<GlobalLoader />}><AllPayments /></Suspense>} loader={getAllPayments}/>
            <Route path='/management/query' element={<Suspense fallback={<GlobalLoader />}><Query /></Suspense> } loader={getAllQuerys}/>
          </Route>
      </Route>
      </Route>
    </Route>
    
  )
);

export default ApplicationRoutes;
